import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { EnterpriseRefComponent } from 'components/upgrade/enterprise-ref'
import * as GA from 'google-analytics'
import {pipe, tap} from 'lodash/fp'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { noMoreUpgradeAvailable } from 'generic/utility'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import * as ReactStrap from 'reactstrap'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import * as Router from '../../router'

export const preventDefaultThen = (fn: any) => pipe(
  tap((e: React.SyntheticEvent) => e.preventDefault()),
  fn,
)

export const stopPropagationThen = (fn: any) => pipe(
  tap((e: React.SyntheticEvent) => e.stopPropagation()),
  fn,
)

export const preventDefault = () => (e: React.SyntheticEvent) => e.preventDefault()

interface ComponentProps {
  children: any,
  href: string,
}

interface DispatchProps {
  push: (href: string) => Router.PushAction,
}

type Props = ComponentProps & DispatchProps

export const Link = connect(
  null,
  {push: Router.push},
)(({href, push, children, ...props}: Props) =>
<ReactStrap.Button {...props} onClick={preventDefaultThen(() => push(href))}>
  {children}
</ReactStrap.Button>)

interface AnchorLinkOwnProps {
  ga4Event?: GA.Event4,
  dispatch?: any,
  callback?: any,
  style?: any,
}

type AnchorLinkProps = ComponentProps & AnchorLinkOwnProps
//& React.HTMLProps<HTMLAnchorElement>

export const AnchorLink = connect(
  (state: any, ownProps: AnchorLinkOwnProps) => (ownProps),
  (dispatch) => ({dispatch}),
)(({href, children, ga4Event, ...props}: AnchorLinkProps) =>
  <a href={href}
    className="anchor-link"
    onClick={preventDefaultThen(() => {
      if ( ga4Event ) {
        props.dispatch(GA.sendGa4Event(ga4Event))
      }
      if ( href ) {
        props.dispatch(Router.push(href))
      }
      if (props.callback) {
        props.callback()
      }},
    )}
    style={props.style}>
    {children}
  </a>)

interface UpgradeLinkProps {
  callback?: any,
  children: any,
  className?: string,
  source: string,
  style?: any,
}

interface UpgradeLinkState {
  loading: boolean,
  activeCommunity: any,
  isNoMoreUpgradeAvailable: boolean,
}

export class UpgradeAnchorLink extends Component<UpgradeLinkProps, UpgradeLinkState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  constructor(props: UpgradeLinkProps) {
    super(props)
    this.state = {
      loading: true,
      activeCommunity: {},
      isNoMoreUpgradeAvailable: true,
    }
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
    ]).then(([activeCommunity]) => {
      this.setState({
        activeCommunity,
        isNoMoreUpgradeAvailable: noMoreUpgradeAvailable(activeCommunity.billingInfo.currentPlan),
        loading: false,
      })
    })
  }

  public render() {
    return (
      this.state.loading ? <Loading size="64" /> :
      <div className="upgrade-button">
        {this.getRenderComponent()}
      </div>
    )
  }

  private getRenderComponent = () => {
    if (this.state.isNoMoreUpgradeAvailable) {
      return <EnterpriseRefComponent showInline={true} />
    }
    return <AnchorLink ga4Event={GA.createUpgradeEvent(this.props.source)}
                     style={this.props.style}
                     href={DashboardMenuOption.billing + '?upgrade_from=' + this.props.source}
                     callback={() => {
                       sessionStorage.setItem(GA.UPGRADE_FROM, this.props.source)
                       if (this.props.callback) {
                        this.props.callback()
                       }
                     }}>
    {this.props.children}
  </AnchorLink>
  }
}
